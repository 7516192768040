$(function () {
	"use strict";

	$("#contactFormId, #orderingFormId").find("[data-val-required]").each(function() {
		var requiredLabel = $(this).siblings("label");
		if (requiredLabel.length > 0) {
			requiredLabel.addClass("required");
		}

		$(this).on("blur", function () {
			var val = $(this).val().trim();
			if ($(this).is(":text") || $(this).is("textarea")) {
				if (val.length)
					$(this).addClass("valid");
				else
					$(this).removeClass("valid");
			}
			else if ($(this).is("[type=email]")) {
				if (isEmail(val))
					$(this).addClass("valid");
				else 
					$(this).removeClass("valid");
			}
		});

	});

	$(".navbar-toggle").on("click", function () {
		$(".navbar-header").toggleClass("open");
		$(this).toggleClass("open");
		$("#top-navigation").toggleClass("show-top-nav");
		if ($("#top-navigation").hasClass("show-top-nav")) {
			$(".language-switcher li.current-item").prependTo($(".language-switcher ul"));
		}

		if ($(".navbar-toggle").hasClass("open")) {
			var homeHref = $(".logo-wrap a").attr("href");
			var home = $("<li/>").addClass("mobile-home-collapsed").append($("<a/>").attr("href", homeHref).text("Home"));
			home = home.wrapAll("<li class='mobile-home-collapsed'/>");
			home.prependTo($("#navigation-wrap #navbar > ul"));

			var languageSwitcher = $("#top-navigation .socialmedia").clone();
			$("#top-navigation .socialmedia").hide();
			if (languageSwitcher.length) {
				languageSwitcher.removeClass("pull-right");
				$("#navigation-wrap > nav").after(languageSwitcher);
			}
		} else {
			$("#top-navigation .socialmedia").show();
			$("#navigation-wrap > .socialmedia").remove();
		}
	});

	var resizeTimer;
	$(window).on("resize", function (e) {
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function () {
			if ($(window).width() > 768) {
				$("#navigation-wrap .mobile-home-collapsed").remove();
			}
		}, 100);
	});


		$(".alert").css({
		position: "fixed",
		width: "100%",
		top: 0,
		left: 0
	});

	$(".alert>button.close").on("click", function () {
		$(".alert").slideUp(function() {
			$(this).remove();
		});
	});

	$(".pointer-scroll").on("click", function () {
		$("html, body").animate({
			scrollTop: $("#main-wrap").offset().top
		}, 700, function() {});
	});

	if ($(".field-validation-error").length) {
		var validationErrorParent = $(".field-validation-error").closest(".form-group");
		var target = validationErrorParent.length ? validationErrorParent : $(".field-validation-error");
		if (target.length) {
			$("html, body").animate({
				scrollTop: target.offset().top
			}, 500, function () { });
		}
	}

});

function isEmail(email) {
	var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	return regex.test(email);
}

